import { Directive, ViewContainerRef, ContentChildren } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';



let /** @type {?} */ input: string[] = ['name', 'size', 'type'];
let /** @type {?} */ outputs: string[] = [];
/**
 * 'e-files' directive represent a file of angular uploader 
 * It must be contained in a Uploader component(`ejs-uploader`). 
 * ```html
 * <ejs-uploader id='fileupload' multiple=true> 
 *   <e-files>
 *    <e-file name='Java' size=23000 type='pdf'></e-file>
 *    <e-file name='C++' size=30000 type='.docx'></e-file>
 *   </e-files>
 * </ejs-uploader>
 * ```
 */
export class UploadedFilesDirective extends ComplexBase<UploadedFilesDirective> {
public directivePropList: any;
/**
 * Specifies the type of the file
 * \@default ''
 */
public type: any;
/**
 * Specifies the name of the file
 * \@default ''
 */
public name: any;
/**
 * Specifies the size of the file
 * \@default null
 */
public size: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-files>e-uploadedfiles',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
}

function UploadedFilesDirective_tsickle_Closure_declarations() {
/** @type {?} */
UploadedFilesDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
UploadedFilesDirective.ctorParameters;
/** @type {?} */
UploadedFilesDirective.prototype.directivePropList;
/**
 * Specifies the type of the file
 * \@default ''
 * @type {?}
 */
UploadedFilesDirective.prototype.type;
/**
 * Specifies the name of the file
 * \@default ''
 * @type {?}
 */
UploadedFilesDirective.prototype.name;
/**
 * Specifies the size of the file
 * \@default null
 * @type {?}
 */
UploadedFilesDirective.prototype.size;
/** @type {?} */
UploadedFilesDirective.prototype.viewContainerRef;
}

/**
 * UploadedFiles Array Directive
 */
export class FilesDirective extends ArrayBase<FilesDirective> {
constructor() {
        super('files');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-uploader>e-files',
    queries: {
        children: new ContentChildren(UploadedFilesDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function FilesDirective_tsickle_Closure_declarations() {
/** @type {?} */
FilesDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
FilesDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
