import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormValidator } from '@syncfusion/ej2-inputs';
export class FormValidators {
/**
 * @param {?} number
 * @return {?}
 */
public static max(number: number|Date): ValidatorFn {
        let /** @type {?} */ max: number|Date = number;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //tslint:disable-next-line
            let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.max({ value: control.value, param: max });
            if (result === true) {
                return null;
            } else {
                return { 'max': true };
            }
        };
    }
/**
 * @param {?} number
 * @return {?}
 */
public static min(number: number|Date): ValidatorFn {
        let /** @type {?} */ min: number|Date = number;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //tslint:disable-next-line
            let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.min({ value: control.value, param: min });
            if (result === true) {
                return null;
            } else {
                return { 'min': true };
            }
        };
    }
/**
 * @param {?} control
 * @return {?}
 */
public static creditcard(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.creditcard({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'cardno': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static date(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.date({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'date': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static dateIso(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.dateIso({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'dateiso': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static digits(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.digits({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'digit': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static email(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.email({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'email': true };
        }
    }
/**
 * @param {?} number
 * @return {?}
 */
public static maxLength(number: number): ValidatorFn {
        let /** @type {?} */ maxlength: number = number;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //tslint:disable-next-line
            let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.maxLength({ value: control.value, param: maxlength });
            if (result === true) {
                return null;
            } else {
                return { 'maxlength': true };
            }
        };
    }
/**
 * @param {?} number
 * @return {?}
 */
public static minLength(number: number): ValidatorFn {
        let /** @type {?} */ minlength: number = number;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //tslint:disable-next-line
            let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.minLength({ value: control.value, param: minlength });
            if (result === true) {
                return null;
            } else {
                return { 'minlength': true };
            }
        };
    }
/**
 * @param {?} control
 * @return {?}
 */
public static number(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.number({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'number': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static required(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = (control.value === null) ? false : ( /** @type {?} */((FormValidator as any))).checkValidator.required({ value: control.value });
        if (result === true) {
            return null;
        }
        else {
            return { 'required': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static tel(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.tel({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'telno': true };
        }
    }
/**
 * @param {?} control
 * @return {?}
 */
public static url(control: AbstractControl): { [key: string]: boolean } | null {
        //tslint:disable-next-line
        let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.url({ value: control.value });
        if (result === true) {
            return null;
        } else {
            return { 'url': true };
        }
    }
/**
 * @param {?} number1
 * @param {?} number2
 * @return {?}
 */
public static rangeLength(number1: number, number2: number): ValidatorFn {
        let /** @type {?} */ minRL: number = number1;
        let /** @type {?} */ maxRL: number = number2;
        //tslint:disable-next-line
        let /** @type {?} */ param: any = [minRL, maxRL];
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //tslint:disable-next-line
            let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.rangeLength({ value: control.value, param: param });
            if (result === true) {
                return null;
            } else {
                return { 'rangelength': true };
            }
        };
    }
/**
 * @param {?} number1
 * @param {?} number2
 * @return {?}
 */
public static range(number1: number, number2: number): ValidatorFn {
        let /** @type {?} */ minR: number = number1;
        let /** @type {?} */ maxR: number = number2;
        //tslint:disable-next-line
        let /** @type {?} */ param1: any = [minR, maxR];
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //tslint:disable-next-line
            let /** @type {?} */ result: boolean = ( /** @type {?} */((FormValidator as any))).checkValidator.range({ value: control.value, param: param1 });
            if (result === true) {
                return null;
            } else {
                return { 'range': true };
            }
        };
    }
}