import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { Signature } from '@syncfusion/ej2-inputs';



export const /** @type {?} */ inputs: string[] = ['backgroundColor','backgroundImage','disabled','enablePersistence','enableRtl','isReadOnly','locale','maxStrokeWidth','minStrokeWidth','saveWithBackground','strokeColor','velocity'];
export const /** @type {?} */ outputs: string[] = ['focus', 'blur', 'beforeSave','change','created'];
export const /** @type {?} */ twoWays: string[] = [];
/**
 * Represents the EJ2 Angular Signature Component.
 * ```html
 * <canvas ejs-signature />
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class SignatureComponent extends Signature implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	beforeSave: any;
	change: any;
public created: any;
public focus: any;
public blur: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: '[ejs-signature]',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SignatureComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function SignatureComponent_tsickle_Closure_declarations() {
/** @type {?} */
SignatureComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SignatureComponent.ctorParameters;
/** @type {?} */
SignatureComponent.prototype.formCompContext;
/** @type {?} */
SignatureComponent.prototype.formContext;
/** @type {?} */
SignatureComponent.prototype.tagObjects;
/** @type {?} */
SignatureComponent.prototype.beforeSave;
/** @type {?} */
SignatureComponent.prototype.change;
/** @type {?} */
SignatureComponent.prototype.created;
/** @type {?} */
SignatureComponent.prototype.focus;
/** @type {?} */
SignatureComponent.prototype.blur;
/** @type {?} */
SignatureComponent.prototype.registerEvents;
/** @type {?} */
SignatureComponent.prototype.addTwoWay;
/** @type {?} */
SignatureComponent.prototype.ngEle;
/** @type {?} */
SignatureComponent.prototype.srenderer;
/** @type {?} */
SignatureComponent.prototype.viewContainerRef;
/** @type {?} */
SignatureComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
