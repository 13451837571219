import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaskedTextBoxComponent } from './maskedtextbox.component';
/**
 * NgModule definition for the MaskedTextBox component.
 */
export class MaskedTextBoxModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        MaskedTextBoxComponent
    ],
    exports: [
        MaskedTextBoxComponent
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function MaskedTextBoxModule_tsickle_Closure_declarations() {
/** @type {?} */
MaskedTextBoxModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MaskedTextBoxModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
