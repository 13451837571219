import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaskedTextBoxComponent } from './maskedtextbox.component';
import { MaskedTextBoxModule } from './maskedtextbox.module';
/**
 * NgModule definition for the MaskedTextBox component with providers.
 */
export class MaskedTextBoxAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, MaskedTextBoxModule],
    exports: [
        MaskedTextBoxModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function MaskedTextBoxAllModule_tsickle_Closure_declarations() {
/** @type {?} */
MaskedTextBoxAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MaskedTextBoxAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
