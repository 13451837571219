import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { Uploader } from '@syncfusion/ej2-inputs';
import { Template } from '@syncfusion/ej2-angular-base';
import { FilesDirective } from './files.directive';

export const /** @type {?} */ inputs: string[] = ['allowedExtensions','asyncSettings','autoUpload','buttons','cssClass','directoryUpload','dropArea','dropEffect','enablePersistence','enableRtl','enabled','files','htmlAttributes','locale','maxFileSize','minFileSize','multiple','sequentialUpload','showFileList','template'];
export const /** @type {?} */ outputs: string[] = ['focus', 'blur', 'actionComplete','beforeRemove','beforeUpload','canceling','change','chunkFailure','chunkSuccess','chunkUploading','clearing','created','failure','fileListRendering','pausing','progress','removing','rendering','resuming','selected','success','uploading'];
export const /** @type {?} */ twoWays: string[] = [];
/**
 * Represents the EJ2 Angular Uploader Component.
 * ```html
 * <ejs-uploader></ejs-uploader>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class UploaderComponent extends Uploader implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	actionComplete: any;
	beforeRemove: any;
	beforeUpload: any;
	canceling: any;
	change: any;
	chunkFailure: any;
	chunkSuccess: any;
	chunkUploading: any;
	clearing: any;
	created: any;
	failure: any;
	fileListRendering: any;
	pausing: any;
	progress: any;
	removing: any;
	rendering: any;
	resuming: any;
	selected: any;
	success: any;
public uploading: any;
public childFiles: any;
public tags: string[] = ['files'];
/**
 * Specifies the HTML string that used to customize the content of each file in the list.
 * 
 * > For more information, refer to the [template](../../uploader/template/) section from the documentation.
 *     
 * \@default null
 */
@Template()
    public template: any;
public focus: any;
public blur: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        this.tagObjects[0].instance = this.childFiles;
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-uploader',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UploaderComponent),
            multi: true
        }
    ],
    queries: {
        childFiles: new ContentChild(FilesDirective)
    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'template': [{ type: ContentChild, args: ['template', ] },],
};
}

function UploaderComponent_tsickle_Closure_declarations() {
/** @type {?} */
UploaderComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
UploaderComponent.ctorParameters;
/** @type {?} */
UploaderComponent.propDecorators;
/** @type {?} */
UploaderComponent.prototype.formCompContext;
/** @type {?} */
UploaderComponent.prototype.formContext;
/** @type {?} */
UploaderComponent.prototype.tagObjects;
/** @type {?} */
UploaderComponent.prototype.actionComplete;
/** @type {?} */
UploaderComponent.prototype.beforeRemove;
/** @type {?} */
UploaderComponent.prototype.beforeUpload;
/** @type {?} */
UploaderComponent.prototype.canceling;
/** @type {?} */
UploaderComponent.prototype.change;
/** @type {?} */
UploaderComponent.prototype.chunkFailure;
/** @type {?} */
UploaderComponent.prototype.chunkSuccess;
/** @type {?} */
UploaderComponent.prototype.chunkUploading;
/** @type {?} */
UploaderComponent.prototype.clearing;
/** @type {?} */
UploaderComponent.prototype.created;
/** @type {?} */
UploaderComponent.prototype.failure;
/** @type {?} */
UploaderComponent.prototype.fileListRendering;
/** @type {?} */
UploaderComponent.prototype.pausing;
/** @type {?} */
UploaderComponent.prototype.progress;
/** @type {?} */
UploaderComponent.prototype.removing;
/** @type {?} */
UploaderComponent.prototype.rendering;
/** @type {?} */
UploaderComponent.prototype.resuming;
/** @type {?} */
UploaderComponent.prototype.selected;
/** @type {?} */
UploaderComponent.prototype.success;
/** @type {?} */
UploaderComponent.prototype.uploading;
/** @type {?} */
UploaderComponent.prototype.childFiles;
/** @type {?} */
UploaderComponent.prototype.tags;
/**
 * Specifies the HTML string that used to customize the content of each file in the list.
 * 
 * > For more information, refer to the [template](../../uploader/template/) section from the documentation.
 *     
 * \@default null
 * @type {?}
 */
UploaderComponent.prototype.template;
/** @type {?} */
UploaderComponent.prototype.focus;
/** @type {?} */
UploaderComponent.prototype.blur;
/** @type {?} */
UploaderComponent.prototype.registerEvents;
/** @type {?} */
UploaderComponent.prototype.addTwoWay;
/** @type {?} */
UploaderComponent.prototype.ngEle;
/** @type {?} */
UploaderComponent.prototype.srenderer;
/** @type {?} */
UploaderComponent.prototype.viewContainerRef;
/** @type {?} */
UploaderComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
