import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerComponent } from './colorpicker.component';
import { ColorPickerModule } from './colorpicker.module';
/**
 * NgModule definition for the ColorPicker component with providers.
 */
export class ColorPickerAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, ColorPickerModule],
    exports: [
        ColorPickerModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ColorPickerAllModule_tsickle_Closure_declarations() {
/** @type {?} */
ColorPickerAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ColorPickerAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
