import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider.component';
import { SliderModule } from './slider.module';
/**
 * NgModule definition for the Slider component with providers.
 */
export class SliderAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, SliderModule],
    exports: [
        SliderModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function SliderAllModule_tsickle_Closure_declarations() {
/** @type {?} */
SliderAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SliderAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
