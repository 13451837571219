import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { Slider } from '@syncfusion/ej2-inputs';



export const /** @type {?} */ inputs: string[] = ['colorRange','cssClass','customValues','enableAnimation','enableHtmlSanitizer','enablePersistence','enableRtl','enabled','limits','locale','max','min','orientation','readonly','showButtons','step','ticks','tooltip','type','value','width'];
export const /** @type {?} */ outputs: string[] = ['focus', 'blur', 'change','changed','created','renderedTicks','renderingTicks','tooltipChange','valueChange'];
export const /** @type {?} */ twoWays: string[] = ['value'];
/**
 * Represents the EJ2 Angular Slider Component.
 * ```html
 * <ejs-slider [value]='value'></ejs-slider>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class SliderComponent extends Slider implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	change: any;
	changed: any;
	created: any;
	renderedTicks: any;
	renderingTicks: any;
	tooltipChange: any;
public valueChange: any;
public focus: any;
public blur: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-slider',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function SliderComponent_tsickle_Closure_declarations() {
/** @type {?} */
SliderComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
SliderComponent.ctorParameters;
/** @type {?} */
SliderComponent.prototype.formCompContext;
/** @type {?} */
SliderComponent.prototype.formContext;
/** @type {?} */
SliderComponent.prototype.tagObjects;
/** @type {?} */
SliderComponent.prototype.change;
/** @type {?} */
SliderComponent.prototype.changed;
/** @type {?} */
SliderComponent.prototype.created;
/** @type {?} */
SliderComponent.prototype.renderedTicks;
/** @type {?} */
SliderComponent.prototype.renderingTicks;
/** @type {?} */
SliderComponent.prototype.tooltipChange;
/** @type {?} */
SliderComponent.prototype.valueChange;
/** @type {?} */
SliderComponent.prototype.focus;
/** @type {?} */
SliderComponent.prototype.blur;
/** @type {?} */
SliderComponent.prototype.registerEvents;
/** @type {?} */
SliderComponent.prototype.addTwoWay;
/** @type {?} */
SliderComponent.prototype.ngEle;
/** @type {?} */
SliderComponent.prototype.srenderer;
/** @type {?} */
SliderComponent.prototype.viewContainerRef;
/** @type {?} */
SliderComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
